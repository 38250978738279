import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import RecentlyViewedComponent, {
  IRecentlyViewedComponentProps,
} from '@components/pages/home/recently-viewed/component';
import { IStore } from '@interfaces/store';


const RecentlyViewed: React.FunctionComponent<IRecentlyViewedComponentProps> = (props) => {

  const recentProductsState = useSelector((state: IStore) => state.common.recentProductsState);
  const sessionId = useSelector((state: IStore) => state.auth.sessionId);
  const isSessionIdWasDefined = useMemo(() => sessionId !== null, []);
  const isHasRecentlyViewedCookie = useMemo(() => recentProductsState, []);

  return isSessionIdWasDefined && isHasRecentlyViewedCookie ? (
    <RecentlyViewedComponent {...props} />
  ) : null;
};

export default RecentlyViewed;
