import React from 'react';
import { useSelector } from 'react-redux';

import SectionProductsList from '@components/common/SectionList/productsList';
import { useTranslation } from '@hooks/useTranslation';
import { IProductInList } from '@interfaces/product';
import { IStore } from '@interfaces/store';

const RecentlyViewedWrapper: React.FunctionComponent<{ hideOutOfStock?: boolean }> = ({ hideOutOfStock }) => {
  const { t } = useTranslation();
  const recentProducts = useSelector((state: IStore) => state.common.recentProducts);
  const loaded = useSelector((state: IStore) => state.common.loaded.recentProducts);

  const products = hideOutOfStock ? recentProducts?.filter((product: IProductInList) => {
    const stockQty = product.colors.map((color) => color.sizes?.map((size) => size.stockQty)?.flat())?.flat();
    const maxStockValue = Math.max(...stockQty);
    return maxStockValue > 0;
  }) : recentProducts;

  return (
    <SectionProductsList
      loaded={loaded}
      products={products}
      wrapperListName={t('common.recently_viewed')}
    />
  );
};

export default RecentlyViewedWrapper;
